body {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.cdnfonts.com/css/enixe");

.video {
  width: 100%;

  background-image: url("../src/images/Background\ Advanced.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 200vh;
  overflow: hidden;
}

.video3 {
  width: 100%;

  background-image: url("../src/images/Background\ Advanced.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100%;
  overflow-y: scroll;
}
/* .video2{
  min-width: 100%;
  max-height: 100%;
  overflow: hidden;
} */
.a1_white {
  z-index: 999;
  width: 100%;
  position: absolute;
  top: 40%;
}
.img-game {
  width: 50%;
  margin: auto;
}
.img2 {
  width: 30%;
}
.joinFooter {
  width: 100%;

  padding: 20px;
  min-height: 100vh;
  background: #0e1b23;
}
.newera {
  font-family: "Rajdhani", sans-serif;
  color: white;
  font-size: 3em;
  text-align: center;
}
.join-btn {
  color: #fff;
  width: 300px;
  padding: 20px 60px 20px 55px;
  background: #0d4f74;
  font-family: "Enixe", sans-serif;

  font-size: 2em;
  font-style: normal;
  margin: auto;
  font-weight: bolder;
  border: none;

  margin-top: 220px;
  line-height: normal;
}
.join-btn:hover {
  background-color: #0d4e74d4;
}
.history-main {
  max-height: 200px;
  overflow-y: scroll;
  width: 50%;
  margin: auto;
  padding: 20px;
  background-color: #0e1b23;
  border: 2px solid white;
}
.history-div {
  border: 2px solid white;
  width: 100%;
  margin: auto;

  padding: 20px;
}
.history {
  color: #fff;

  text-align: left;
  font-family: "Rajdhani", sans-serif;
  font-size: 20px;
  font-style: normal;
  width: 100%;

  margin: 23px auto;
  margin-left: 24px;

  font-weight: 400;
  line-height: normal;
}
.current-flex {
  display: flex;

  width: 700px;
  height: 330px;
  margin: auto;
}
.photo-current {
  width: 230px;
  height: 200px;
  border-radius: 5px;
  margin-top: 35px;
}
.join-btn2 {
  color: #fff;

  width: 120px;
  height: 50px;
  background: #0d4f74;
  font-family: "Rajdhani", sans-serif;

  font-size: 15px;
  font-style: normal;

  font-weight: 900;
  border: 0;
  z-index: 999;
  outline: 0;
  -webkit-user-select: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}
.Leave {
  color: #fff;

  width: 120px;
  height: 50px;
  background: #0d4f74;
  font-family: "Rajdhani", sans-serif;

  font-size: 20px;
  font-style: normal;

  font-weight: 900;
  border-radius: 10px;
  margin-right: 80px;
  border: 0;

  letter-spacing: 5px;
}
.logout {
  color: #fff;

  background: #0d4f74;
  font-family: "Enixe", sans-serif;

  font-size: 2em;
  font-style: normal;
  margin-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  border: 0;
  z-index: 999;
  outline: 0;
  -webkit-user-select: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}
.join-btn-main {
  text-align: center;
  width: 25%;
  margin: auto;
}
.joinback {
  background: #0e1b23;
  height: 100vh;
  
  position: fixed;
  overflow-y: scroll;
  align-items: center;
  text-align: center;
  padding-top: 100px;
}
.join-font {
  color: #fff;

  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.join-form-main {
  width: 50%;
  margin: auto;
  height: 95vh;
}
.input-form {
  width: 100%;
  margin-top: 20px;
  border: none;
  background: #d9d9d9;
  color: #000;
  font-family: "Rajdhani", "sans-serif";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  border: none;
  outline: none;
}
.input-form::placeholder {
  color: black;
  font-weight: 600;
  font-family: "Rajdhani", sans-serif;
  font-size: 20px;
}
.right {
  color: rgba(255, 255, 255, 0.33);
  font-family: "Rajdhani", sans-serif;
  margin-top: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.img-game1 {
  width: 50%;
  margin: auto;
  position: relative;
  top: 70vh;
}
.img-game2 {
  width: 50%;
  margin: auto;
  position: relative;
  top: 80vh;
}
.color {
  background-color: #0e1b23;
  height: 100vh;
  width: 100%;
}
.back {
  background-image: url("../src/images/Color_Gradient\ 1.png");
  width: 60%;
  height: 50vh;
  position: relative;

  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.thankYou {
  color: #fff;
  font-size: 1em;
  font-family: "Poppins", "sans-serif";

  margin: auto;
  text-align: center;
  font-style: normal;
  margin-top: 5px;
  font-weight: 400;
  line-height: normal;
}
.position {
  position: relative;
  top: 40%;
}
@media (min-width: 2000px) {
  .thankYou {
    font-size: 38px;
  }
  .join-btn2 {
    width: 200px;
    font-size: 20px;
  }
}
@media (max-width: 1280px) {
  .back {
    width: 95%;
    padding: 0px;
  }
}
@media (max-width: 900px) {
  .img-game {
    width: 80%;
  }
  .img-game1 {
    width: 80%;
  }
  .img2 {
    width: 200px;
  }
  .join-form-main {
    width: 95%;
  }
}
.g-recaptcha {
  z-index: 1;
}

@media (max-width: 600px) {
  .position {
    top: 40%;
  }
  .history-main {
    width: 90%;
    padding: 5px;
  }
  .profile-flex2 {
    padding: 0px;
  }
  .history {
    font-size: 12px;
  }
  .history-div {
    width: 100%;
  }
  .joinback {
    height:100vh;
    padding-top: 20px;
    padding-bottom: 300px;

   
  }
  .join-form-main {
    width: 100%;
  }
  .join-font {
    font-size: 1em;
  }
  .join-btn {
    width: 250px;
    padding: 10px 20px 10px 20px;
  }

  .joinFooter {
    height: auto;
    align-items: center;
  }

  .img-game1 {
    top: 30vh;
  }
  .join-btn-main {
    width: 80%;
  }
  .thankYou {
    font-size: 10px;
  }
  .right {
    position: relative;
    margin-top: 10px;
  }
}

/* ride */
.rideSlide {
  width: 100%;
  height: 500px;

  margin-top: 20px;
}
.ride-main-pic {
  width: 400px;
}
.RidePic {
  background-color: rgba(14, 27, 35, 1);
  /* box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px; */
  width: 100%;
  /* border-radius: 30px; */
  margin: auto;
  text-align: center;
  padding: 30px;
  height: auto;
  font-family: "Rajdhani", sans-serif;
}
.ridedes {
  color: white;
  margin-top: 10px;
  font-family: "Rajdhani", sans-serif;
}
.bookslot {
  background-color: rgba(14, 27, 35, 1);
  width: 500px;
  text-align: center;
  margin: 150px auto;
  padding-left: 50px;
  padding-right: 50px;
  border: 2px solid black;

  font-size: 25px;
}

.bookfont {
  text-align: left;
  font-family: "Rajdhani", sans-serif;
  color: white;
  margin-top: 10px;
  margin-left: 30px;
}
.confirm {
  color: #fff;

  padding: 20px 30px 20px 30px;
  background: #0d4f74;
  font-family: "Enixe", sans-serif;

  font-style: normal;
  margin: auto;
  font-weight: bolder;
  border: none;

  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 15px;
  width: 200px;
  cursor: pointer;
  z-index: 9000;
}
.timeselection {
  background-color: #0d4f74;
  color: #fff;
  font-family: "Rajdhani", sans-serif;
  font-weight: 400;
}
.scanner-container {
  position: absolute;
  top: 20%;
  min-width: 100%;
  min-height: 200vh;

  z-index: 9999;
}
.scanner-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 2px;
  background-color: red; /* Adjust as needed */
  animation: scanAnimation 1s linear infinite;
}

@keyframes scanAnimation {
  0% {
    top: 0px;
  }
  100% {
    bottom: 0px;
  }
}

.X {
  background-color: #0d4f74;
  color: white;
  border: none;
  outline: none;
  position: relative;
  top: 30%;

  z-index: 99999;
  font-family: "Rajdhani", sans-serif;
}
.X2 {
  color: white;
  border: none;
  outline: none;
  position: absolute;
  top: 80px;
  font-family: "Rajdhani", sans-serif;
  border-radius: 50%;
  z-index: 99999;
  font-size: 30px;
}

.rides-book-button {
  width: "100%";
  text-align: center;
}
.updateProfile {
  background-color: #0e1b23;
  padding: 20px;
  margin: 20px auto;
}
@media (max-width: 600px) {
  .bookslot {
    width: 90%;
  }
  .confirm {
    width: 150px;
    padding: 10px 15px 10px 15px;
  }
  .ride-main-pic {
    width: 90%;
  }
  .scanner-container {
    width: 250px;
    margin: auto;
  }
  .rides-book-button {
    width: 100%;
  }
  .rideSlide {
    height: auto;
  }
  .rides-mobile-book {
    width: 200px;
  }
}
.blurred-background {
  filter: blur(30px);
}

/* profile */

.profile-main {
  width: 80%;

  height: auto;
  margin: auto;
}
.abso {
  position: absolute;
  top: 100px;
  width: 100%;
}
.profile-des {
  width: 100%;
  border: 2px solid black;
  height: 100px;
  margin: 50px auto;
  background-color: rgba(14, 27, 35, 1);
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.profile-name {
  float: right;
}
.profile-pic {
  width: 150px;
  height: 150px;
  background-color: #0d4f74;
  border-radius: 50%;
  position: relative;
  top: -100px;
  margin-left: 20px;
}

.profile-des-para {
  color: #fff;
  margin-right: 20px;
  text-align: right;
  font-family: "Rajdhani", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  width: 370px;
  line-height: normal;
  letter-spacing: 6px;
}
.profile-des-para-main {
  width: 400px;
  display: flex;
}

.profile-more {
  width: 100%;
  border: 2px solid black;
  height: auto;
  margin: 50px auto;
  background-color: rgba(14, 27, 35, 1);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.profile-flex {
  display: flex;

  width: 100%;
  justify-content: space-between;
}
.profile-coin {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-between;
}
.profile-flex1 {
  flex-basis: 20%;
  background: #0d4f74;
  padding: 10px;

  text-align: center;
  height: 300px;
}
.profile-flex2 {
  flex-basis: 78%;
  background: #0d4f74;
  padding: 10px;

  height: 300px;
}
.profile-wallet-main {
  color: #fff;

  text-align: center;
  font-family: "Rajdhani", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: bolder;
  text-transform: uppercase;
  line-height: normal;
  margin-top: 10px;
}
.profile-wallet-main2 {
  color: #fff;
  margin-top: 100px;

  text-align: center;
  font-family: "Rajdhani", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: bolder;
  text-transform: uppercase;
  line-height: normal;
}
.p-name {
  color: #fff;

  text-align: right;
  font-family: "Rajdhani", sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: bolder;
  position: relative;
  top: 40px;
}

.mobile {
  display: none;
}
.nav {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
  padding: 10px;
}
.edit-user {
  width: 60%;
  margin: auto;
}
@media (max-width: 600px) {
  .profile-flex {
    flex-direction: column;
  }
  .edit-user {
    width: 90%;
  }
  .updateProfile {
    width: 100%;
  }
  .profile-des-para-main {
    width: 100%;
    flex-direction: column;
  }
  .profile-main {
    top: 120px;
  }
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
  .profile-des {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .profile-des-para {
    width: 70%;
    margin: auto;
    text-align: center;
  }
  .p-name {
    top: 0px;
  }
  .profile-flex1 {
    margin-bottom: 10px;
    padding: 50px;
  }
  .profile-flex2 {
    margin-bottom: 10px;
    padding: 50px;
  }
  .profile-wallet-main {
    margin: 0px;
  }
  .abso {
    top: 140px;
  }
  .nav {
    display: block;
    align-items: center;
    text-align: center;
  }
}

/* landing page */

.landing-grid {
  background-image: url("./images/gridimg.jpg");
  height: 100vh;
  width: 100%;
}
.display-flex {
}

.leave-container {
  background-color: #0d4f74;
  padding: 30px;
  width: 300px;
  margin: auto;
  border: 2px solid rgba(14, 27, 35, 1);
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 5px;
}
.leave-heading {
  font-family: "Rajdhani", sans-serif;
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
}
.leave-position {
  position: absolute;
  width: 98%;
  top: 30px;
 
  z-index: 999;
}

@media (max-width: 990px) {
  .current-flex {
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  .profile-coin{
    display: block;
  }
  .profile-flex1 {
    height: auto;
    margin-bottom: 0px;
    padding: 20px;
    align-items: center;
  }
  .profile-flex2 {
    margin-bottom: 0px;
  }
  .Leave {
    margin: auto;
  }
  .history {
    margin-left: 0px;
    font-size: 20px;
  }
  .photo-current {
    width: 200px;
  }
  .profile-more {
    padding: 5px;
  }
  .profile-wallet-main2 {
    margin-top: 0px;
  }
  .leave-container{
    width: 100%;

  }
}

.rating-card{
  width: 280px;
  height: 300px;
  background-color: #0d4f74;
  margin: auto;
  padding: 30px;
  border-radius: 20px;
  
}
.rating-userPhoto{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
 

}
.rating-star{
  margin-top: 10px;
  color: white;
  font-size: 18px;
}
.rating-text{
  margin-top: 10px;
  color: white;
  font-size: 18px;
}



.userContainer{
  background-color: #0d4f74;
  width: 300px;
  padding: 20px;
  margin: auto;
  text-align: center;
  
}