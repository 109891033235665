.user-search-main{
    width: 80%;
    margin: auto;
    
}
.cards-main{
    width:250px ;
  
    background-color:rgba(14, 27, 35, 1) ;
    margin: 20px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.card-pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;

    margin: auto;
    background-color: aliceblue;
}

.card-name{
    text-align: center;
    font-size: 15px;
    color: white;
    font-family: "Rajdhani", sans-serif;
    margin-top: 5px;
}
.card-bottom{
    width: 100%;
    height: 50px;
    background-color:#0d4f74;
    padding-top: 5px;
}

.ADD{
    text-align: center;
    font-size: 25px;
    color: white;
    font-family: "Rajdhani", sans-serif;
}